require('./bootstrap.js');

/*import $ from 'jquery';
window.$ = window.jQuery = $;*/

window.d3 = require("d3");

import { StatusCodes } from 'http-status-codes';
window.StatusCodes = StatusCodes;

import { Runtime } from "@observablehq/runtime";
import { Inspector } from "@observablehq/inspector";
window.Runtime = Runtime;
window.Inspector = Inspector;